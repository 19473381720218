<template>
    <router-view></router-view>
  </template>
  
  <script>
  export default {
    data: function () {
      return {};
    },
    watch: {},
    computed: {},
    methods: {},
  };
  </script>
  