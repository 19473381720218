<template>
  <div>
    <div>
      <div class="space-y-12">
        <div class="pb-7">
          <h3 class="font-semibold leading-7 text-gray-900 py-3">Resume</h3>
          <p class="mt-1 text-base leading-6 text-gray-600">
            Hey, thanks for dropping by! Just wanted to give you a heads up that this
            <strong>awesome feature</strong>
            is still a work in progress. Don't worry though, we're working hard to make it even better! Make sure to
            swing by again soon to see the final result. Cheers!
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dzlaaytu7/image/upload/v1688246190/iStock-1427672271_gvcifk.jpg"
          class="mx-auto mt-2"
          alt="Calender Under Construction"
          style="max-width: 500px"
        />
      </div>
    </div>
  </div>
</template>
<script></script>
<style></style>
